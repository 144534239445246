import {makeAutoObservable} from "mobx";
import {RootStore} from "./RootStore";

export class ApplicationStore {

  private _gatekeeperEnabled = false;

  enableGatekeeper = () => {
    this._gatekeeperEnabled = true;
  }
  get gatekeeperEnabled(){
    return this._gatekeeperEnabled;
  }

  constructor(private store:RootStore) {
    makeAutoObservable(this);
  }
}