import './index.css';
import React from 'react';
import {observer} from "mobx-react-lite";
import {useKeycloak} from "./hooks/Keycloak";
import {AppContext, useAppContext} from "./app-context";
import {DefaultContent} from "./components/DefaultContent";
import {AppSelectContent} from "./components/AppSelectContent";
import {GateKeeperButton} from "./components/buttons/GateKeeperButton";

function App() {

  const {keycloak} = useKeycloak();
  const {store, service, application} = useAppContext();

  return (
    <div className="App no-select">
      <AppContext.Provider value={{store, service, application}}>
        {keycloak.authenticated ? <AppSelectContent/> : <DefaultContent/>}
        <GateKeeperButton/>
      </AppContext.Provider>
    </div>
  );
}

export default observer(App);
