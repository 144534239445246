import React from "react";

export const DefaultContent:React.FC = () => {
  return (
    <div
      className={'no-select'}
      style={{
        animation: 'fade-in 3s',
        position: 'fixed',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)'
      }}
    >
      <div style={{
        fontFamily: 'primary',
        fontSize: '10vw'
      }}>
        Drawbackz.net
      </div>
    </div>
  );
}