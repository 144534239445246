import React from "react";

interface IAppButtonProps {
  title?: string;
  description?: string;
  href?: string;
}

const AppButton: React.FC<IAppButtonProps> = (props) => {

  const {title, description, href} = props;

  const handleClick = () => {
    if (href) {
      window.location.assign(href);
    }
  }

  return (
    <div
      style={{
        display: 'inline-flex',
        width:'33%',
        flexDirection: 'column',
        justifyContent: 'center',
        alignContent: 'center',
        padding: 15,
        margin: 20,
        backgroundColor: 'rgba(0,0,0,0.5)',
        border: '2px solid',
        borderRadius: 8,
        cursor: 'pointer'
      }}
      onClick={handleClick}
    >
      <div style={{fontSize:'2em'}}>
        {title}
      </div>
      <hr style={{width:'100%', opacity:0.5}}/>
      <div style={{fontSize:'1.5em'}}>
        {description}
      </div>
    </div>
  );
}

export const AppSelectContent: React.FC = () => {
  return (
    <div>
      <div style={{
        display: 'inline-flex',
        width: '100%',
        height: '100%',
        flexWrap: 'wrap',
        flexDirection: 'row',
        alignContent: 'center',
        justifyContent: 'center'
      }}>
        <AppButton
          title={'Monroe Events'}
          description={'Monroe County 911 Event Feed w/Cameras'}
          href={'https://monroe.drawbackz.net'}
        />
        <AppButton
          title={'Duelyst Decks'}
          description={'A list of Duelyst cards'}
          href={'https://duelyst.drawbackz.net'}
        />
      </div>
    </div>
  );
}