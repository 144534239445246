import App from './App';
import React from 'react';
import ReactDOM from 'react-dom/client';
import keycloak from "./Keycloak";
const root = ReactDOM.createRoot(document.getElementById('root'));

keycloak.init({onLoad: 'check-sso'}).catch(console.log).finally(() => {
  root.render(
    <React.StrictMode>
      <App />
    </React.StrictMode>
  );
});