import {makeAutoObservable} from "mobx";
import {RootStore} from "./RootStore";
import {UserFingerprint} from "../models/UserFingerprint";
import {ClientJS} from "clientjs";

const client = new ClientJS();

export class UserStore {

  constructor(private store:RootStore) {
    makeAutoObservable(this);
  }

  get fingerprint():UserFingerprint {
    return {
      system:{
        cpu:client.getCPU(),
        os:{
          name:client.getOS(),
          version:client.getOSVersion()
        },
        tz:client.getTimeZone(),
        lang:client.getSystemLanguage()
      },
      browser:{
        name:client.getBrowser(),
        userAgent:client.getUserAgent(),
        version:client.getBrowserVersion(),
        major:client.getBrowserMajorVersion(),
        screenResolution:client.getAvailableResolution(),
        plugins:client.getPlugins()
      },
      device:{
        name:client.getDevice(),
        type:client.getDeviceType(),
        vendor:client.getDeviceVendor(),
        screen:{
          screenResolution:client.getCurrentResolution(),
          dpi:{
            x:client.getDeviceXDPI(),
            y:client.getDeviceYDPI(),
          }
        }
      },
      fingerprint:{
        engine:{
          name:client.getEngine(),
          version:client.getEngineVersion()
        },
        value:client.getFingerprint()
      }
    } as UserFingerprint;
  }
}