import {RootStore} from "./stores/RootStore";
import {RootService} from "./services/RootService";
import {ApplicationViewModel} from "./view-models/ApplicationViewModel";
import {createContext, useContext} from "react";

const store = new RootStore();
const service = new RootService(store);
const application = new ApplicationViewModel(store, service);
const AppContext = createContext({store, service, application});

export const useAppContext = () => {
  return useContext(AppContext);
}

export {AppContext};