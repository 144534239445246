import {makeAutoObservable} from "mobx";
import {UserStore} from "./UserStore";
import {ApplicationStore} from "./ApplicationStore";

export class RootStore {

  user = new UserStore(this);
  application = new ApplicationStore(this);

  constructor() {
    makeAutoObservable(this);
  }
}