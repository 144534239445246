import React from "react";
import {useKeycloak} from "../../hooks/Keycloak";

interface IGateKeeperButtonProps {
  onClick?: () => void;
  onDoubleClick?: () => void;
}
export const GateKeeperButton:React.FC<IGateKeeperButtonProps> = () => {
  const {keycloak} = useKeycloak();
  return (
    <div
      className={`gatekeeper-icon ${keycloak.authenticated ? ' active' : 'inactive'} transition`}
      style={{
        position: 'fixed',
        height: 15,
        width: 15,
        bottom: 10,
        right: 10
      }}
      onDoubleClick={() =>
        keycloak.authenticated ? keycloak.logout() : keycloak.login()
      }
    />
  );
}
